/* Variables */
:root {
  --header-height: 60px;

  --color-primary: #0d1b29;
  --color-secondary: #274d76;
  --color-tertiary: #3d83c9;

  --color-font-primary: #65ceff;

  --color-font-secondary: #79d4ff;
  --color-link-secondary: #4d95b6;

  --color-font-tertiary: #3a7d9c;
}

/* Main containers */
html,
body,
#root,
#router-root {
  min-height: 100vh;
  color: var(--color-font-primary);
}

body{
  background-color: var(--color-secondary);
}

#root {
  display: flex;
  flex-direction: column;
}

#router-root {
  flex: 1 0 auto;
}

/* Footer */
.footer {
  flex-shrink: 0;
  font-size: 0.75rem;
  background-color: var(--color-primary);
  color: var(--color-font-secondary);
  padding: 4px;
}

.footer a{
  color: var(--color-link-secondary);
}

/* Header */
.main-nav {
  display: flex;
  height: var(--header-height);
  min-width: 100%;
  background: var(--color-primary);
  scroll-snap-align: end;
  top: 0;
  z-index: 1020;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: var(--color-font-primary) var(--color-link-secondary)
}

.main-nav::-webkit-scrollbar {
  width: 4px; /* Mostly for vertical scrollbars */
  height: 4px; /* Mostly for horizontal scrollbars */
}

.main-nav::-webkit-scrollbar-thumb { /* Foreground */
  background: var(--color-font-primary);
}
.main-nav::-webkit-scrollbar-track { /* Background */
  background: var(--color-link-secondary);
}

.main-nav a {
  margin: auto 12px;
  color: var(--color-font-secondary);
  text-decoration: none;
  font-weight: 500;
  transition: color 100ms ease-in, fill 100ms ease-in;
  fill: var(--color-font-primary);
}

.main-nav a:hover{
  color: var(--color-font-tertiary);
  fill: var(--color-font-tertiary);
}

.main-nav svg {
  height: calc(0.3 * var(--header-height));
  padding: 2px;
  margin-bottom: 2px;
  width: auto;
  vertical-align: middle;
}

#nav-logo {
  height: var(--header-height);
  margin-left: 0;
}

#nav-logo img {
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .main-nav span{
    display: none;
  }

  .main-nav svg {
    height: calc(0.6 * var(--header-height));
  }
}

/* Elements */

button {
  width: 20%;
  margin: 8px;
  color: var(--color-font-primary);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  background: var(--color-primary);
  border-radius: .25rem;
  border-width: 0;
  transition: 
    color .15s ease-in-out,
    background-color .15s ease-in-out
}

button:hover{
  color: var(--color-primary);
  background: var(--color-tertiary);
}

button:disabled{
  color: grey;
  background-color: rgb(73, 73, 73);
}

.set-selector {
  color: var(--color-primary);
}

/* Home */

.home {
  display: flex;
  flex-direction: column;
  position: relative;
  font-weight: 500;
}

.home * {
  margin: 1rem;
  /* max-width: 30rem; */
}

.home h1 {
  text-align: center;
}

/* Card counter */

.card-counter-main {
  scroll-snap-type: y mandatory;

  /* Vars */
  --button-radius: 1vh;
}

.count-nav {
  display: flex;
  background: var(--color-primary);
  scroll-snap-align: end;
  z-index: 1040;
}

.count-nav .set-selector {
  width: 80%;
  padding: 8px;
}

.no-set-placeholder {
  text-align: center;
  font-size: 3rem;
  margin-top: 5%;
}

.setlist {
  /* margin: 3px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  column-gap: 2px;
  row-gap: 4px;
  margin: 4px;
}

.setlist img{
  width: 100%;
  height: auto;
}

.cardbox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  height: 100%;
  scroll-snap-align: center;
}

.cardbox .column {
  position: relative;
  height: auto;
  float: left;
}

.cardbox img {
  border-radius: var(--button-radius);
}

.column.card-info{
  padding: 5% 2%;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
}

.column .card-name {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  padding-bottom: 10%;
}

.column .card-count {
  font-size: 1.2rem;
}

.cardbox::after{
  content: "";
  display: table;
  clear: both;
}

/* Counter buttons */

.cardbox .btn-row{
  position: absolute;
  display: block;
  width: min(57vh, 100%);
  height: 35%;
}

.cardbox .btn {
  position: absolute;
  display: block;
  margin: auto;
  left:0;
  right: min(12vh, 20%);
  width: min(45vh, 80%);
  height: 35%;
  border-radius: 0px;
  background: transparent;
  font-size: 2rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0);
}

.cardbox .sb{
  left: min(45vh, 80%);
  right: 0;
  width: min(12vh, 20%);
  font-size: 1rem;
}

.cardbox button span{
  display: flex;
  transform: rotate(90deg);
}

.cardbox .btn:hover {
  background: rgba(10, 10, 10, 0.2);
  color: var(--color-primary);
}

.cardbox .btn:active {
  background: rgba(10, 10, 10, 0.25);
}

.cardbox .btn-small {
  height: 15%;
}

.cardbox .btn-plusfour {
  top:0%;
  border-radius: var(--button-radius) 0 0 0;
}

.cardbox .btn-plusfour-sb {
  top:0%;
  border-radius: 0 var(--button-radius) 0 0;
}

.cardbox .btn-plusone {
  top:15%;
}

.cardbox .btn-minusone {
  bottom:15%;
}

.cardbox .btn-minusfour {
  bottom: 0%;
  border-radius: 0 0 0 var(--button-radius);
}

.cardbox .btn-minusfour-sb {
  bottom: 0%;
  border-radius: 0 0 var(--button-radius) 0;
}

/* Tournament tracker */

.tournament-tracker{
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 4px;
  align-items: center;
  justify-content: center;
  /* margin: 4px; */
}

.tracker-round{
  /* border: 2px solid var(--color-primary); */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 0.5rem;
  /* background:rgba(10, 10, 10, 0.2); */
}

@media only screen and (max-width: 600px) {
  .tournament-tracker {
    grid-template-columns: 1fr;
  }

  .tracker-round {
    grid-template-columns: 1fr;
  }
}

.reporter-root>button {
  /* display: flex; */
  width: 45%;
  min-height: 50px;
  background: var(--color-tertiary);
}

.reporter-root button:hover {
  /* display: flex; */
  background: var(--color-primary);
  color: var(--color-tertiary);
}

.reporter-root h1 {
  padding-left: 20px;
}

.tracker-round.header input {
  box-sizing: border-box;
  width: 100%;
  min-height: 38px;
  font-size: 1rem;
  padding-left: 8px;
  border-radius: 6px;
}

.tournament-date-picker{
  position: relative;
  display:flex;
  /* width:100%; */
}

.tracker-round:nth-of-type(2n){
  background:rgba(10, 10, 10, 0.2);
}

.round-header {
  display: flex;
  flex-direction: column;
}

.round-title {
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 1rem;
}

.opp-selector {
  color: var(--color-primary);
}

.mtg-color-picker {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 4px 0;
}

.mtg-color-picker * {
  margin: 4px;
}

.record-picker{
  position: relative;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  padding: 2px;
}

.record-ticker{
  position: relative;
  display: flex;
  font-size: 3.5rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
}

.record-ticker button {
  position: absolute;
  display: block;
  top:0;
  left:0;
  right:0;
  margin: auto;
  height: 50%;
  width: 100%;
  border-radius: 4px 4px 0 0;
  background: transparent;
  border:1px solid rgba(10, 10, 10, 0.2);
  /* font-size: x-large; */
  /* font-display: none; */
}

.record-ticker .min{
  top: initial;
  bottom: 0;
  border-radius: 0 0 4px 4px;
}

.record-ticker button:hover{
  background: rgba(10, 10, 10, 0.2);
}

.record-ticker button:active {
  background: rgba(10, 10, 10, 0.25);
}

/* || Trade binder */

.binder-root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.trade-binder{
  display:grid;
  /* object-fit: cover; */
  max-height: 100vh;
  column-gap: 2px;
  row-gap: 2px;
  height: auto;
  width: 100%;
  padding: 2px;
  box-sizing: border-box;
}

.trade-card{
  /* height: 100%; */
  width: 100%;
  position: relative;
}

.trade-card img{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  /* object-fit: cover; */
  /* width: 100%;
  height: auto; */
  border-radius: 0.7rem;
  /* height: 30vh;
  width: auto; */
}

.trade-card .actual-image{
  position: absolute;
  top: 0;
  left: 0;
}

.trade-card .sticker {
  position: absolute;
  right: 30px;
  bottom: 20px;
  width: 50%;
  max-width: 50%;
  animation: slide-card-in 2s;
}

.stickerHolder{
  opacity: 100%;
  transition: opacity 0.2s;
  /* fixes text morphing in transitions */
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.stickerHolder:hover{
  opacity: 35%;
}

.stickerHolder img{
  position: relative;
  width: 100%;
  height: 100%;
}

.stickerHolder span{
  font-size: 1.7vh;
}

.stickerHolder .price{
  right: 7%;
  top: 27%;
  position: absolute;
  font-family: 'Courier New', Courier, monospace;
  color: var(--color-primary);
  font-size: 1rem;
  font-weight: bold;
}

.page-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.page-buttons button{
  width: 33%;
  height: 100%;
}

.pagebuttons div {
  /* height: 20% */
}

.trade-card .foil {
  position: absolute;
  width: 100%;
  animation-name: foil-effect;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  overflow:hidden;
}

@keyframes foil-effect {
  0% {
    /* transform: translate(-100%, -100%); */
    opacity: 0.1
  }
  50% {
    opacity: 1;
  }
  100% {
    /* transform: translate(100%, 100%); */
    opacity: 0.1;
  }
}

.trade-card img {
  animation-name: slide-card-in;
}

@keyframes slide-card-in{
  0%{
    transform: translateY(-120%);
    opacity: 0;
  }
  80%{
    transform: translateY(-2%);
    opacity: 1;
  }
  100%{
    transform: translateY(0)
  }
}

/* Label Printer */

.labelprinter {
  padding: 4px;
  position: relative;
  padding-bottom: 50px;
}

.labelprinter > .setSelector {
  width: 100%;
}

.set-svg-icon,
.label-preview {
  height: auto;
  width: 100px;
  margin: 2px;
}

.set-svg-icon {
  border: 1px solid gray;
  border-radius: 4px;
  padding: 4px;
}

.set-svg-icon:hover {
  background: rgba(10, 10, 10, 0.2);
}

#label-container {
  background: rgba(10, 10, 10, 0.2);
  border-radius: 4px;
  padding: 4px;
}

.label-preview{
  filter: drop-shadow(1px 1px 1px grey);
}

.print-button {
  width: calc(100% - 8px);
  position: fixed;
  bottom: 4px;
}